<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <label>Add Items</label>

        <div v-if="invoiceWoInventory">
            <product-dropdown :raw="false"
                              @input="addProduct"
                              :isservice="true"
                              width="100%" />
        </div>
        <div v-else>
            <product-dropdown :wareHouseId="wareHouseId"
                              :raw="false"
                              @input="addProduct"
                              :isservice="true"
                              width="100%" />
        </div>

        <div class="table-responsive  mt-4">
            <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead class="m-0">
                    <tr>
                        <th style="width: 20px;">
                            #
                        </th>
                        <th style="width: 70px;">
                            {{ $t('SaleItem.Product') }}
                        </th>
                        <th class="text-center" style="width: 280px;">
                            {{ $t('SaleItem.Description') }}
                        </th>
                        <th class="text-center" style="width: 100px;">
                            {{ $t('SaleItem.ModelStyle') }}
                        </th>
                        <th class="text-center" style="width: 100px;">
                            {{ $t('SaleItem.UnitPrice') }}
                        </th>
                        <th class="text-center" style="width: 100px;" v-if="!invoiceWoInventory && formName != 'Quotation'">
                            {{ $t('SaleItem.CurrentQuantity') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.HighQty') }}
                        </th>
                        <th class="text-center" style="width: 100px;">
                            {{ $t('SaleItem.Quantity') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TOTALQTY') }}
                        </th>
                        <th class="text-center" style="width: 100px;" hidden>
                            {{ $t('SaleItem.ReturnDays') }}
                        </th>
                        <th style="width: 100px;" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                            {{ $t('SaleItem.Bundle') }}
                        </th>

                        <th class="text-center" style="width: 100px;" v-if="isSerial">
                            {{ $t('SaleItem.Serial') }}
                        </th>
                        <th style="width: 100px;" v-if="isSerial">
                            {{ $t('SaleItem.Guarantee') }}
                        </th>

                        <th class="text-center" style="width: 100px;">
                            {{ $t('SaleItem.DISC%') }}
                        </th>
                        <!--<th class="text-center" style="width: 100px;">
                        {{ $t('SaleItem.FixDisc') }}
                    </th>-->

                        <th v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" style="width: 105px;">
                            {{ $t('AddPurchase.TaxMethod') }}
                        </th>
                        <th v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" style="width: 130px;">
                            {{ $t('AddPurchase.VAT%') }}
                        </th>

                        <th style="width: 60px;">
                            {{ $t('SaleItem.Free') }}
                        </th>
                        <th class="text-right" style="width: 100px;">
                            {{ $t('SaleItem.LineTotal') }}
                        </th>
                        <th style="width: 40px;"></th>
                    </tr>
                </thead>
                <tbody id="sale-item">
                    <template v-for="(prod, index) in saleProducts">
                        <tr :key="rendered + index" v-bind:class="{'bg-danger':prod.outOfStock}" style="background:#EAF1FE ;">
                            <td>{{index+1}}</td>
                            <td v-if="prod.productId!=null">
                                <div v-if="prod.productId!=null">
                                    <span>{{products.find(x => x.id == prod.productId).code}}</span> <br />
                                    <!--<span style="font-size: 10px;">
                                    {{$i18n.locale == 'en'? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                                </span>-->

                                    <span style="font-size: 10px;" v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount > 0" class="badge badge-pill badge-success">
                                        Rs {{(products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount).toFixed(3).slice(0,-1)}}, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                    </span>
                                    <span style="font-size: 10px;" v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.discountPercentage > 0" class="badge badge-pill badge-success">
                                        {{(products.find(x => x.id == prod.productId).promotionOffer.discountPercentage).toFixed(3).slice(0,-1)}}%, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                    </span>
                                    <span style="font-size: 10px;" v-if="products.find(x => x.id == prod.productId).bundleCategory != undefined" class="badge badge-pill badge-success">
                                        {{products.find(x => x.id == prod.productId).bundleCategory.buy}} + {{products.find(x => x.id == prod.productId).bundleCategory.get}}, ({{products.find(x => x.id == prod.productId).bundleCategory.stockLimit - products.find(x => x.id == prod.productId).bundleCategory.quantityOut}})
                                    </span>
                                </div>
                            </td>

                            <td v-bind:colspan="prod.productId==null?2:1">
                                <textarea data-gramm="false" class="form-control input-border " style="background-color: #ffffff;padding: 0 5px 0 5px;" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                          v-model="prod.description" />
                            </td>
                            <td class="text-center">
                                <input v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'text-right'"
                                       type="text" v-model="prod.styleNumber"
                                       @focus="$event.target.select()"
                                       class="form-control input-border text-center tableHoverOn" />
                            </td>
                            <td v-on:dblclick="counter += 1, openmodel('unitPrice'+index)" v-if="!changePriceDuringSale && dayStart=='true'">
                                <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('unitPrice'+index))==true?'true':'false'" v-model="prod.unitPrice" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.unitPrice, 'unitPrice', prod)" />
                            </td>
                            <td v-else>
                                <decimal-to-fixed v-model="prod.unitPrice" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.unitPrice, 'unitPrice', prod)" />
                            </td>
                            <td class="text-center" v-if="!invoiceWoInventory && formName != 'Quotation'">
                                {{prod.currentQuantity}}
                            </td>
                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                <input type="number" v-model="prod.highQty"
                                       style=""
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'highQty', prod)" />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>
                            </td>
                            <td class="text-center">
                                <input v-model="prod.quantity " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       type="number"
                                       @focus="$event.target.select()"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />
                                <small style="font-weight: 500;font-size:70%;" v-if="isMultiUnit=='true'">
                                    {{prod.basicUnit}}
                                </small>
                            </td>

                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                {{prod.totalPiece}}
                            </td>
                            <td v-if="prod.saleReturnDays > 0" hidden>
                                <input v-model="prod.saleReturnDays "
                                       type="number"
                                       class="form-control input-border text-center tableHoverOn " />
                            </td>
                            <td class="text-center" v-else hidden>
                                <span>--</span>
                            </td>
                            <td class="text-center" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                                <span class="badge badge-pill badge-info">{{prod.bundleOffer}}</span>
                            </td>

                            <td class="text-center" v-if="isSerial">
                                <button @click="AddSerial(prod)" v-if="prod.isSerial" title="Add Serial" class="btn btn-primary btn-sm"> Add Serial </button>
                                <span v-else>-</span>
                            </td>
                            <td class="border-top-0  text-center" v-if="isSerial">
                                <datepicker v-model="prod.guaranteeDate" v-if="prod.guarantee" />
                                <span v-else>-</span>
                            </td>

                            <!--<td v-if="(!giveDiscountDuringSale && dayStart==='true') " v-on:dblclick="counter += 1, openmodel1('discount'+index)">
                            <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('discount'+index))==true?'true':'false'" v-model="prod.discount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.discount, 'discount', prod)" />
                        </td>-->
                            <td>
                                <div class="row">
                                    <div class="col-9 pl-3 pr-0" v-if="prod.discountSign == '%'">
                                        <div v-if="(!giveDiscountDuringSale && dayStart==='true') " v-on:dblclick="counter += 1, openmodel1('discount'+index)">
                                            <decimal-to-fixed v-model="prod.discount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.discount, 'discount', prod)" />
                                        </div>
                                        <div v-else>
                                            <decimal-to-fixed v-model="prod.discount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.discount, 'discount', prod)" />
                                        </div>
                                    </div>
                                    <div class="col-9 pl-3 pr-0" v-else-if="prod.discountSign == 'F'">
                                        <div v-if="(!giveDiscountDuringSale && dayStart==='true')" v-on:dblclick="counter += 1, openmodel2('fixDiscount'+index)">
                                            <decimal-to-fixed v-model="prod.fixDiscount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                                        </div>
                                        <div v-else>
                                            <decimal-to-fixed v-model="prod.fixDiscount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                                        </div>
                                    </div>
                                    <div class="col-9 pl-3 pr-0" v-else>
                                    </div>
                                    <div class="col-3 p-0">
                                        <a href="javascript:void(0)" class="btn btn-primary" style="padding: 7px 4px; border-radius: 0;" v-on:click="OnChangeDiscountType(prod)">
                                            <span style="font-size:12px;">{{prod.discountSign}}</span>
                                        </a>
                                    </div>
                                </div>
                            </td>

                            <!--<td v-if="(!giveDiscountDuringSale && dayStart==='true')" v-on:dblclick="counter += 1, openmodel2('fixDiscount'+index)">
                            <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('fixDiscount'+index))==true?'true':'false'" v-model="prod.fixDiscount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                        </td>

                        <td v-else>
                            <decimal-to-fixed v-model="prod.fixDiscount" :disable="prod.discount != 0?true:false" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                        </td>-->
                            <td hidden>
                                <taxratedropdown v-model="prod.taxRateId"
                                                 @input="getVatValue($event, prod)" :dropdownpo="'dropdownpo'"></taxratedropdown>
                            </td>

                            <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'">
                                <multiselect :options="options" :disabled="formName === 'saleReturn'?true:false" v-model="prod.taxMethod" @input="getTaxMethod(prod.taxMethod, prod)" :show-labels="false" v-bind:placeholder="$t('PurchaseItem.TaxMethod')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </td>

                            <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'">
                                <taxratedropdown v-model="prod.taxRateId" :isDisable="formName === 'saleReturn'?true:false" @input="getVatValue(prod.taxRateId, prod)" />
                            </td>

                            <td class="text-center">
                                <input type="checkbox" class="checkBoxHeight" v-model="prod.isFree"
                                       v-on:change="updateLineTotal(prod.isFree, 'isFree', prod)">
                            </td>
                            <td class="text-right">
                                {{currency}} {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                            <td>
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm btn-round btn-icon float-right">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>

                    <tr style="background:#EAF1FE ;">
                        <td></td>

                        <td colspan="2">
                            <textarea data-gramm="false" v-model="newItem.description" class="form-control input-border " style="background-color: #ffffff;padding: 0 5px 0 5px;" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </td>
                        <td class="text-center">
                            <input v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'text-right'"
                                   type="text" v-model="newItem.styleNumber"
                                   @focus="$event.target.select()"
                                   class="form-control input-border text-center tableHoverOn" />
                        </td>
                        <td>
                            <decimal-to-fixed v-model="newItem.unitPrice" />
                        </td>

                        <td class="text-center" v-if="!invoiceWoInventory && formName != 'Quotation'">
                        </td>

                        <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                            <input type="number" v-model="newItem.highQty"
                                   @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                   class="form-control input-border text-center tableHoverOn" />
                        </td>

                        <td class="text-center">
                            <input v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                   type="number" v-model="newItem.quantity"
                                   @focus="$event.target.select()"
                                   class="form-control input-border text-center tableHoverOn" />
                        </td>

                        <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                        </td>

                        <td hidden>
                            <input type="number"
                                   class="form-control input-border text-center tableHoverOn " />
                        </td>

                        <td class="text-center" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                        </td>

                        <td class="text-center" v-if="isSerial">
                        </td>

                        <td class="border-top-0  text-center" v-if="isSerial">
                        </td>

                        <td>
                            <div class="row">
                                <div class="col-9 pl-3 pr-0" v-if="newItem.discountSign == '%'">
                                    <decimal-to-fixed v-model="newItem.discount" v-bind:salePriceCheck="false" />
                                </div>
                                <div class="col-9 pl-3 pr-0" v-else-if="newItem.discountSign == 'F'">
                                    <decimal-to-fixed v-model="newItem.fixDiscount" v-bind:salePriceCheck="false" />
                                </div>
                                <div class="col-9 pl-3 pr-0" v-else>
                                </div>
                                <div class="col-3 p-0">
                                    <a href="javascript:void(0)" class="btn btn-primary" style="padding: 7px 4px; border-radius: 0;" v-on:click="NewItemChangeDiscount(newItem)">
                                        <span style="font-size:12px;">{{newItem.discountSign}}</span>
                                    </a>
                                </div>
                            </div>
                            <!--<decimal-to-fixed v-model="newItem.discount" v-bind:disable="newItem.fixDiscount != 0?true:false" />-->
                        </td>

                        <!--<td>
                        <decimal-to-fixed v-model="newItem.fixDiscount" v-bind:disable="newItem.discount != 0?true:false" />
                    </td>-->

                        <td hidden>
                            <taxratedropdown :dropdownpo="'dropdownpo'"></taxratedropdown>
                        </td>

                        <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" class="text-center">
                        </td>

                        <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" class="text-right">
                        </td>

                        <td class="text-center">
                        </td>

                        <td class="text-right">
                        </td>

                        <td>
                            <button @click="newItemProduct()"
                                    title="Add Item" v-bind:disabled="newItem.description==''"
                                    class="btn btn-primary btn-sm btn-round btn-icon float-right">
                                <i class="fa fa-check"></i>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>


        <div class="row  p-0 m-0">
            <div class="col-7 ">

            </div>
            <div class="col-5 text-right p-0">

                <table style="width:100%">
                    <thead>
                        <tr>
                            <th class="text-right pr-2" style="width:50%;">Discount/Adjustment:</th>
                            <td class="text-left">
                                <input v-model="oveallDiscount" @focus="$event.target.select()" @input="calcuateSummary()" class="form-control" type="number" />
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>

        </div>
        <div class=" table-responsive mt-3"
             v-bind:key="rendered + 'g'">
            <table class="table add_table_list_bg" style="table-layout:fixed;">
                <thead class="m-0" style=" background-color: #3178F6; color:#ffffff;">
                    <tr class="text-right">
                        <th class="text-center" style="width:100px;">
                            {{ $t('SaleItem.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TotalCarton') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TotalPieces') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('SaleItem.TotalQty') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleItem.Total') }}
                        </th>
                        <th style="width:100px;" v-if="isDiscountBeforeVat">
                            {{ $t('SaleItem.Disc') }}
                        </th>
                        <th style="width:100px;" v-else>
                            {{ $t('SaleItem.TotalVat') }}
                        </th>

                        <th style="width:160px;" v-if="isDiscountBeforeVat">
                            {{ $t('SaleItem.TotalAfterDisc') }}
                        </th>
                        <th style="width:160px;" v-else>
                            {{ $t('SaleItem.TotalWithVat') }}
                        </th>

                        <th style="width:100px;" v-if="isDiscountBeforeVat">
                            {{ $t('SaleItem.TotalVat') }}
                        </th>
                        <th style="width:100px;" v-else>
                            {{ $t('SaleItem.Disc') }}
                        </th>

                        <th v-if="summary.bundleAmount > 0" style="width:100px;">
                            {{ $t('SaleItem.BundleAmount') }}
                        </th>
                        <th style="width:155px;" v-if="isDiscountBeforeVat">
                            {{ $t('SaleItem.TotalWithVat') }}
                        </th>
                        <th style="width:155px;" v-else>
                            {{ $t('SaleItem.TotalAfterDisc') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right samary_tbl_pd" style="background-color:#EAF1FE;">
                        <td class="text-center">
                            {{ summary.item}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalCarton}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalPieces}}
                        </td>
                        <td class="text-center">
                            {{ summary.qty}}
                        </td>

                        <td>
                            {{parseFloat(summary.total).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                        </td>
                        <td v-if="isDiscountBeforeVat">
                            {{  parseFloat(summary.discount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")  }}
                        </td>
                        <td v-else>
                            {{currency}}  {{ (parseFloat(summary.vat)).toFixed(3).slice(0,-1) | formatAmount }}
                            
                        </td>

                        <td v-if="isDiscountBeforeVat">
                            {{currency}}  {{  parseFloat(summary.withDisc).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>
                        <td v-else>
                            {{currency}}  {{  parseFloat(summary.withVat).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>

                        <td v-if="isDiscountBeforeVat">
                            {{currency}}  {{ (parseFloat(summary.vat)).toFixed(3).slice(0,-1) | formatAmount }}
                        </td>
                        <td v-else>
                            {{  parseFloat(summary.discount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")  }}
                        </td>

                        <td v-if="summary.bundleAmount > 0">
                            {{currency}} {{ parseFloat(summary.bundleAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                        </td>
                        <td v-if="isDiscountBeforeVat">
                            {{currency}} {{  parseFloat(summary.withVat).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>
                        <td v-else>
                            {{currency}} {{  parseFloat(summary.withDisc).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <authorize-user-model :authorize="authorize"
                              :show="show"
                              v-if="show"
                              @result="result"
                              @close="show = false" />

        <add-serial-model :item="serialItem"
                          :show="showSerial"
                          v-if="showSerial"
                          @input="updateSerial"
                          @close="showSerial = false" />
    </div>
</template>


<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "SaleItem",
        props: ['saleItems', 'wareHouseId', 'saleOrderId', 'taxMethod', 'taxRateId', 'formName', 'discountProp'],
        mixins: [clickMixin],
        components: {
            Multiselect
        },
        data: function () {
            return {
                oveallDiscount:0,
                isDiscountBeforeVat:false,
                newItem: {
                    description: '',
                    unitPrice: 0,
                    highQty: 0,
                    quantity: 0,
                    discount: 0,
                    fixDiscount: 0,
                    discountSign: '%',
                    styleNumber:''
                },


                dayStart: '',
                isSerial: false,
                soInventoryReserve: false,
                decimalQuantity: false,
                invoiceWoInventory: false,
                fixDiscount: '',
                discount: '',
                bundle: '',
                counter: 0,
                isMultiUnit: '',
                isAuthour: {
                    changePriceDuringSale: false,
                    giveDiscountDuringSale: false,
                    column: '',
                },
                changePriceDuringSale: false,
                giveDiscountDuringSale: false,
                useQuantity: false,
                show: false,
                authorize: {
                    column: '',
                    userName: '',
                    password: '',
                },
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                saleProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    discount: 0,
                    withDisc: 0,
                    vat: 0,
                    withVat: 0,
                    bundleAmount: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                count: 0,
                productList: [],
                options: [],
                serialItem: '',
                showSerial: false,
                saleDefaultVat: '',
            };
        },
        validations: {},
        filter: {},
        methods: {
            NewItemChangeDiscount: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                }
            },
            OnChangeDiscountType: function (prod) {
                if (prod.discountSign === '%') {
                    prod.discountSign = 'F';
                    prod.fixDiscount = 0
                    prod.discount = 0
                    this.updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)
                }
                else {
                    prod.discountSign = '%';
                    prod.discount = 0
                    prod.fixDiscount = 0
                    this.updateLineTotal(prod.discount, 'discount', prod)
                }
            },
            newItemProduct: function () {
                var taxRateId = '';
                var taxMethod = '';
                if (this.saleDefaultVat == 'DefaultVatHead' || this.saleDefaultVat == 'DefaultVatHeadItem') {
                    taxRateId = this.taxRateId;
                    taxMethod = this.taxMethod;
                }
                else {
                    taxRateId = localStorage.getItem('TaxRateId');
                    taxMethod = localStorage.getItem('taxMethod');
                }
                console.log(taxMethod)
                var vat = this.vats.find((value) => value.id == taxRateId);

                var rowId = this.createUUID();
                this.saleProducts.push({
                    rowId: rowId,
                    productId: null,
                    unitPrice: this.newItem.unitPrice,
                    quantity: this.newItem.quantity,
                    soQty: 0,
                    highQty: 0,
                    discount: this.newItem.discount,
                    fixDiscount: this.newItem.fixDiscount,
                    lineItemVAt: 0,
                    description: this.newItem.description,
                    styleNumber: this.newItem.styleNumber,
                    serviceItem: true,
                    currentQuantity: 0,
                    promotionId: null,
                    bundleId: null,
                    taxRateId: taxRateId,
                    saleReturnDays: 0,
                    taxMethod: this.vatSelectOnSale ? this.taxMethod : localStorage.getItem('taxMethod'),
                    rate: vat.rate,
                    serial: '',
                    guaranteeDate: '',
                    isSerial: false,
                    guarantee: false,
                    lineTotal: 0,
                    buy: 0,
                    get: 0,
                    quantityLimit: 0,
                    offerQuantity: 0,
                    unitPerPack: 0,
                    levelOneUnit: '',
                    isFree: false,
                    discountSign: this.newItem.discountSign
                });

                this.newItem.description = '';
                this.newItem.styleNumber = '';
                this.newItem.unitPrice = 0;
                this.newItem.highQty = 0;
                this.newItem.quantity = 0;
                this.newItem.discount = 0;
                this.newItem.fixDiscount = 0;
                this.newItem.styleNumber = '';

                var product = this.saleProducts.find((x) => {
                    return x.rowId == rowId;
                });

                this.updateLineTotal(product.quantity, "quantity", product);
            },


            AddSerial: function (item) {

                this.serialItem = item;
                this.showSerial = true;
            },

            updateSerial: function (serial, item) {

                var prod = this.saleProducts.find(x => x.rowId == item.rowId);
                if (prod != undefined) {
                    prod.serial = serial;
                }
                this.showSerial = false;
            },

            GetProductList: function () {

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                this.isRaw = this.raw == undefined ? false : this.raw;
                //search = search == undefined ? '' : search;
                // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;

                this.$https
                    .get("/Product/GetProductBarcode?isRaw=" + root.isRaw + '&wareHouseId=' + this.wareHouseId, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.productList = response.data.results.products;

                        }
                    });


            },
            onBarcodeScanned(barcode) {

                if (localStorage.getItem("BarcodeScan") != 'SaleItem')
                    return
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var warehouseId = ''
                if (!this.invoiceWoInventory) {


                    warehouseId = root.wareHouseId
                }

                this.$https.get('/Product/GetProductInformation?searchTerm=' + barcode + '&wareHouseId=' + warehouseId + "&isDropdown=true" + '&isDropdown=' + false, { headers: { "Authorization": `Bearer ${token}` } })

                    .then(function (response) {
                        if (response.data != null) {
                            var product1 = [];
                            response.data.results.products.forEach(function (product) {
                                product1.push({
                                    id: product.id,
                                    englishName: product.englishName != '' ? product.englishName : product.arabicName,
                                    arabicName: product.arabicName != '' ? product.arabicName : product.englishName,
                                    assortment: product.assortment,
                                    barCode: product.barCode,
                                    basicUnit: product.basicUnit,
                                    brandId: product.brandId,
                                    bundleCategory: product.bundleCategory,
                                    categoryId: product.categoryId,
                                    code: product.code,
                                    colorId: product.colorId,
                                    colorName: product.colorName,
                                    colorNameArabic: product.colorNameArabic,
                                    description: product.description,
                                    inventory: product.inventory,
                                    isActive: product.isActive,
                                    image: product.image,
                                    isExpire: product.isExpire,
                                    isRaw: product.isRaw,
                                    length: product.length,
                                    levelOneUnit: product.levelOneUnit,
                                    stockLevel: product.stockLevel,
                                    originId: product.originId,
                                    promotionOffer: product.promotionOffer,
                                    purchasePrice: product.purchasePrice,
                                    salePrice: product.salePrice,
                                    salePriceUnit: product.salePriceUnit,
                                    saleReturnDays: product.saleReturnDays,
                                    shelf: product.shelf,
                                    sizeId: product.sizeId,
                                    sizeName: product.sizeName,
                                    sizeNameArabic: product.sizeNameArabic,
                                    styleNumber: product.styleNumber,
                                    subCategoryId: product.subCategoryId,
                                    taxMethod: product.taxMethod,
                                    taxRate: product.taxRate,
                                    taxRateId: product.taxRateId,
                                    unit: product.unit,
                                    unitId: product.unitId,
                                    unitPerPack: product.unitPerPack,
                                    width: product.width,

                                })
                            })

                            root.addProduct(product1[0].id, product1[0])
                        }
                    });



            },

            result: function (x) {
                this.isAuthour = x;
            },
            openmodel: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel1: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel2: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },

            changeProduct: function (NewProdId, rowId) {
                this.saleProducts = this.saleProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);
            },

            calcuateSummary: function () {
                
                this.summary.item = this.saleProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }
                else {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }

                this.summary.total = this.saleProducts.reduce((total, prod) =>
                    total + (prod.isFree ? 0 : (prod.totalPiece) * prod.unitPrice), 0).toFixed(3).slice(0, -1);
               
                /*just calculate discount*/
                var discount = this.saleProducts.filter((x) => (x.discount != 0 || x.discount != "") && x.offerQuantity != 0)
                    .reduce((discount, prod) => discount +
                        prod.diff ?
                        ((prod.diff * prod.unitPrice * prod.discount) / 100) :
                        ((prod.totalPiece < (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) ? ((prod.totalPiece * prod.unitPrice * prod.discount) / 100) :
                            ((prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity) * prod.unitPrice * prod.discount) / 100), 0);

                /*just calculate fix discount*/
                var fixDiscount = this.saleProducts
                    .filter((x) => (x.fixDiscount != 0 || x.fixDiscount != "") && x.offerQuantity != 0)
                    .reduce((discount, prod) =>
                        discount + prod.offerQuantity ? ((prod.totalPiece < (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) ? prod.fixDiscount * prod.totalPiece : prod.fixDiscount * (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) : 0, 0);


                /*just calculate discount without promotion*/
                var discountOnly = 0;
                if (this.isDiscountBeforeVat) {
                    discountOnly = this.saleProducts
                        .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.isFree ? 0 : (prod.totalPiece ? (prod.offerQuantity ? 0 : (((prod.totalPiece * prod.unitPrice) * prod.discount) / 100)) : 0)), 0);

                }
                else {
                    discountOnly = this.saleProducts
                        .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                        .reduce((discount, prod) =>
                            discount + (prod.isFree ? 0 : (prod.totalPiece ? (prod.offerQuantity ? 0 : ((((prod.totalPiece * prod.unitPrice) + prod.lineItemVAt) * prod.discount) / 100)) : 0)), 0);

                }
                /*just calculate fix discount without promotion*/
                var fixDiscountOnly = this.saleProducts
                    .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "" || x.offerQuantity != 0)
                    .reduce((discount, prod) =>
                        discount + (prod.isFree ? 0 : (prod.totalPiece ? (prod.offerQuantity ? 0 : (prod.totalPiece * prod.fixDiscount)) : 0)), 0);


                this.summary.discount = (parseFloat(discount) + parseFloat(fixDiscount) + fixDiscountOnly + discountOnly ).toFixed(3).slice(0, -1);

                

                this.summary.vat = this.saleProducts.reduce((vat, prod) => (vat + (prod.isFree ? 0 : prod.lineItemVAt)), 0).toFixed(3).slice(0, -1);
                

                //var taxmethod = this.saleProducts.find(function (x) {
                //    return x.taxMethod == ("Inclusive" || "شامل")
                //})

                var exclusiveVat = this.saleProducts.reduce((vat, prod) => (vat + ((prod.isFree || prod.taxMethod == ("Inclusive" || "شامل")) ? 0 : prod.lineItemVAt)), 0).toFixed(3).slice(0, -1);
                this.oveallDiscount = (this.oveallDiscount == '' || this.oveallDiscount == null) ? 0 : parseFloat(this.oveallDiscount)
                if (parseFloat(this.summary.total - this.summary.discount) < parseFloat(this.oveallDiscount)) {
                    this.oveallDiscount = parseFloat(this.summary.total - this.summary.discount)

                }
                this.summary.discount = (parseFloat(this.summary.discount) + parseFloat(this.oveallDiscount)).toFixed(3).slice(0, -1)
                if (this.isDiscountBeforeVat) {

                    this.summary.withDisc = (this.summary.total - this.summary.discount).toFixed(3).slice(0, -1);
                    var vat = this.vats.find((value) => value.id == this.taxRateId);
                    var calculateVAt = this.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.summary.withDisc) * vat.rate) / (100 + vat.rate) : (parseFloat(this.summary.withDisc) * vat.rate) / 100 ;
                    this.summary.vat = calculateVAt
                    this.summary.withVat = this.taxMethod == ("Inclusive" || "شامل") ? (parseFloat(this.summary.withDisc)).toFixed(3).slice(0, -1) : (parseFloat(this.summary.withDisc) + parseFloat(calculateVAt)).toFixed(3).slice(0, -1);
                }
                else {
                    this.summary.withVat = (parseFloat(this.summary.total) + parseFloat(exclusiveVat)).toFixed(3).slice(0, -1);
                    this.summary.withDisc = (this.summary.withVat - this.summary.discount ).toFixed(3).slice(0, -1);
                }
                


                //calculate bundle Amount
                if (this.saleProducts.filter(x => x.isBundleOffer).length > 0) {

                    //get bundle get quantity
                    var bundle = {
                        item: 0,
                        qty: 0,
                        total: 0,
                        discount: 0,
                        withDisc: 0,
                        vat: 0,
                        withVat: 0,
                        quantityLimit: 0
                    };

                    var bundleProducts = this.saleProducts.filter(x => x.isBundleOffer != undefined && x.offerQuantity > 0);

                    bundle.total = bundleProducts.reduce((total, prod) =>
                        total + prod.offerQuantity * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                    //var bundleExclusiveTax = bundleProducts.reduce((total, prod) =>
                    //    total + (prod.taxMethod == "Exclusive" ? (bundle.total * prod.rate/100) : 0), 0);

                    var discountBundle = bundleProducts.filter((x) => x.discount != 0 || x.discount != "")
                        .reduce((discount, prod) =>
                            discount + (prod.offerQuantity * prod.unitPrice * prod.discount) / 100, 0);

                    var fixDiscountBundle = bundleProducts
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "")
                        .reduce((discount, prod) => discount + prod.fixDiscount, 0);

                    bundle.discount = (parseFloat(discountBundle) + parseFloat(fixDiscountBundle)).toFixed(3).slice(0, -1);

                    bundle.withDisc = (bundle.total - bundle.discount).toFixed(3).slice(0, -1);

                    bundle.vat = bundleProducts
                        .reduce((vat, prod) => vat + (((prod.unitPrice * prod.offerQuantity) -
                            ((prod.unitPrice * prod.offerQuantity * prod.discount) / 100)) *
                            parseFloat(prod.rate)) / ((prod.taxMethod == "Exclusive" || prod.taxMethod == "غير شامل") ? 100 : prod.rate + 100), 0).toFixed(3).slice(0, -1);

                    this.summary.bundleAmount = (parseFloat(bundle.withDisc) + parseFloat(exclusiveVat)).toFixed(3).slice(0, -1);
                    this.summary.withVat = (this.summary.withVat - bundle.withDisc);
                } else {
                    this.summary.bundleAmount = 0;
                }

                this.$emit("input", this.saleProducts, this.oveallDiscount);

                this.$emit("summary", this.summary);
            },

            updateLineTotal: function (e, prop, product) {
                var root = this;
                if (e != undefined) {
                    var discount = product.discount == 0 || product.discount == "" ? product.fixDiscount == 0 || product.fixDiscount == ""
                        ? 0
                        : product.fixDiscount
                        : product.discount;

                    if (prop == "unitPrice") {
                        product.unitPrice = e;
                    }

                    if (prop == "quantity") {
                        if (e <= 0 || e == '') {
                            e = 0;
                        }
                        if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                            e = parseFloat(String(e).slice(0, -1))
                        product.quantity = this.decimalQuantity ? e : Math.round(e);
                    }
                    if (prop == "highQty") {
                        if (e < 0 || e == '' || e == undefined) {
                            e = 0;
                        }
                        product.highQty = Math.round(e);
                    }
                    product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);

                    if (product.productId != null) {
                        var prod = root.products.find((x) => x.id == product.productId);

                        if (prod.promotionOffer != null) {
                            if (product.totalPiece > 0 && moment().format("DD MMM YYYY") >= moment(prod.promotionOffer.fromDate).format("DD MMM YYYY") &&
                                moment().format("DD MMM YYYY") <= moment(prod.promotionOffer.toDate).format("DD MMM YYYY")) {
                                product.fixDiscount = prod.promotionOffer.fixedDiscount;
                                product.discount = prod.promotionOffer.discountPercentage;
                                product.offerQuantity = prod.promotionOffer.totalPiece;
                                this.$set(product, 'isOfferQty', true);
                            } else {
                                if (product.isOfferQty) {
                                    product.fixDiscount = 0;
                                    product.discount = 0;
                                    product.offerQuantity = 0;
                                }
                                this.$set(product, 'isOfferQty', false);
                            }
                        }

                        if (prod.bundleCategory != null) {
                            if (product.totalPiece >= prod.bundleCategory.buy && moment().format("DD MMM YYYY") >= moment(prod.bundleCategory.fromDate).format("DD MMM YYYY") &&
                                moment().format("DD MMM YYYY") <= moment(prod.bundleCategory.toDate).format("DD MMM YYYY")) {
                                this.$set(product, 'bundleOffer', prod.bundleCategory.buy.toString() + " + " + prod.bundleCategory.get.toString());
                                this.$set(product, 'get', prod.bundleCategory.get);
                                this.$set(product, 'buy', prod.bundleCategory.buy);
                                this.$set(product, 'quantityLimit', prod.bundleCategory.quantityLimit);
                                this.$set(product, 'isBundleOffer', true);
                            } else {
                                this.$set(product, 'bundleOffer', "");
                                this.$set(product, 'get', 0);
                                this.$set(product, 'buy', 0);
                                this.$set(product, 'quantityLimit', 0);
                                this.$set(product, 'isBundleOffer', false);
                            }
                            //bundle category calculation
                            if (product.quantityLimit != undefined && parseFloat(product.totalPiece) >= (product.get + product.buy)) {
                                if ((product.get + product.buy) > 0) {
                                    product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                    if ((prod.bundleCategory.quantityOut + product.offerQuantity) <= prod.bundleCategory.stockLimit) {
                                        if (product.offerQuantity <= product.quantityLimit) {
                                            product.offerQuantity = product.offerQuantity * product.get;
                                        }
                                        else {
                                            product.offerQuantity = product.quantityLimit * product.get;
                                        }
                                    }
                                    else {
                                        var diffBundle = prod.bundleCategory.stockLimit - prod.bundleCategory.quantityOut;
                                        if (diffBundle > product.quantityLimit) {
                                            product.offerQuantity = product.quantityLimit * product.get;
                                        }
                                        else {
                                            product.offerQuantity = diffBundle * product.get;
                                        }
                                    }
                                }
                                else {
                                    product.offerQuantity = 0;
                                }
                            }
                            else {
                                if ((product.get + product.buy) > 0) {
                                    product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                }
                                else {
                                    product.offerQuantity = 0;
                                }
                            }
                            //bundle category calculation end
                        }
                    }


                    if (!this.invoiceWoInventory && product.productId != null && this.formName != 'Quotation') {
                        var bundleQuantity = product.bundleOfferQuantity == undefined ? 0 : product.bundleOfferQuantity;
                        if (!product.serviceItem) {
                            if (prod.inventory != null) {
                                if (parseFloat(product.totalPiece) + bundleQuantity > (prod.inventory.currentQuantity + ((this.saleOrderId != null && this.saleOrderId != '' && this.soInventoryReserve) ? parseFloat(product.soQty) : 0))) {
                                    this.$set(product, 'outOfStock', true);
                                } else {
                                    this.$set(product, 'outOfStock', false);
                                }
                            }
                            else {
                                this.$set(product, 'outOfStock', true);
                            }
                        }
                    }


                    //End Calculate offer
                    if (prop == "discount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > 100) {
                            e = 100;
                        }
                        product.discount = e;
                    }

                    if (prop == "fixDiscount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > product.unitPrice) {
                            e = product.unitPrice;
                        }
                        product.fixDiscount = e;
                    }

                    var vat = 0;
                    var total = 0;
                    var calculateVAt = 0;
                    //here we will select quantity after deduct bundle quantity
                    if (product.offerQuantity > 0) {

                        if (product.isOfferQty) {
                            if (product.totalPiece > 0) {
                                if ((product.totalPiece) <= (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) {
                                    this.$set(product, 'remainingStockLimit', (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut));

                                    if (product.totalPiece <= product.offerQuantity) {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (product.totalPiece * product.unitPrice * product.discount) / 100;

                                    }
                                    else {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.offerQuantity) : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                                    }
                                }
                                else {
                                    discount = product.discount == 0 ? (product.fixDiscount * (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) : ((prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut) * product.unitPrice * product.discount) / 100;
                                }

                                vat = this.vats.find((value) => value.id == product.taxRateId);
                                total = product.totalPiece * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }

                            }
                            else {
                                total = product.offerQuantity * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }
                            }
                        } else {
                            discount = product.discount == 0 ? product.fixDiscount : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                            vat = this.vats.find((value) => value.id == product.taxRateId);

                            total = (product.totalPiece - product.offerQuantity) * product.unitPrice - discount;
                            if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                product.lineItemVAt = calculateVAt;
                                //product.lineTotal = total - calculateVAt;
                                product.lineTotal = total;
                            }
                            else {
                                calculateVAt = (total * vat.rate) / 100;
                                product.lineItemVAt = calculateVAt;
                                product.lineTotal = total + calculateVAt;
                            }
                        }

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts);

                    }
                    else {
                        //isDiscountBeforeVat
                        vat = this.vats.find((value) => value.id == product.taxRateId);

                       
                        if (this.isDiscountBeforeVat) {
                            discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                            total = product.totalPiece * product.unitPrice - discount;
                            if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                product.lineItemVAt = calculateVAt;
                                product.lineTotal = total;
                            }
                            else {
                                calculateVAt = (total * vat.rate) / 100;
                                product.lineTotal = total + calculateVAt;
                                product.lineItemVAt = calculateVAt;
                            }
                        }
                        else {
                           
                            total = product.totalPiece * product.unitPrice;
                            if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (((product.totalPiece * product.unitPrice) + calculateVAt) * product.discount) / 100;
                                product.lineItemVAt = calculateVAt;
                                product.lineTotal = total - discount;
                            }
                            else {
                                calculateVAt = (total * vat.rate) / 100;
                                discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : ((total + calculateVAt) * product.discount) / 100;
                                product.lineTotal = total + calculateVAt - discount;
                                product.lineItemVAt = calculateVAt;
                            }
                           
                        }
                        

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts);
                    }
                }
            },

            addProduct: function (productId, newProduct, qty, price, so, soItem) {


                if (productId == null) {
                    var vat = this.vats.find((value) => value.id == soItem.taxRateId);

                    this.saleProducts.push({
                        rowId: soItem.id,
                        itemId: soItem.id,
                        productId: soItem.productId,
                        unitPrice: soItem.unitPrice == 0 ? '0' : soItem.unitPrice,
                        quantity: qty,
                        highQty: 0,
                        discount: soItem.discount,
                        offerQuantity: 0,
                        fixDiscount: soItem.fixDiscount,
                        taxRateId: soItem.taxRateId,
                        taxMethod: soItem.taxMethod,
                        rate: vat.rate,
                        soQty: qty,
                        currentQuantity: 0,
                        saleReturnDays: 0,
                        lineTotal: 0,
                        unitPerPack: 0,
                        levelOneUnit: '',
                        basicUnit: '',
                        description: soItem.description,
                        serviceItem: soItem.serviceItem,
                        isFree: soItem.isFree,
                        serial: null,
                        guaranteeDate: '',
                        isSerial: false,
                        guarantee: false,
                        styleNumber: soItem.styleNumber,
                        discountSign: '%',
                    });

                    var item = this.saleProducts.find((x) => {
                        return x.rowId == soItem.id;
                    });

                    this.updateLineTotal(item.unitPrice, "unitPrice", item);
                    this.updateLineTotal(item.quantity, "quantity", item);
                    this.updateLineTotal(item.highQty, "highQty", item);
                }
                else {
                    if (this.saleProducts.some(x => x.productId == productId)) {
                        var prd = this.saleProducts.find(x => x.productId == productId);
                        prd.quantity++;
                        this.updateLineTotal(prd.quantity, "quantity", prd);
                    }
                    else {

                        if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                            this.products.push(newProduct);
                        }
                        var prod = this.products.find((x) => x.id == productId);

                        var rate = 0;
                        var taxRateId = '';
                        var taxMethod = '';
                        if (this.saleDefaultVat == 'DefaultVat' || this.saleDefaultVat == 'DefaultVatItem') {
                            if (prod.taxRateId != "00000000-0000-0000-0000-000000000000" && prod.taxRateId != undefined) {
                                rate = this.getVatValue(prod.taxRateId, prod);
                            }
                            taxRateId = prod.taxRateId;
                            taxMethod = prod.taxMethod;
                        }
                        if (this.saleDefaultVat == 'DefaultVatHead' || this.saleDefaultVat == 'DefaultVatHeadItem') {
                            if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined) {
                                rate = this.getVatValue(this.taxRateId, prod);
                            }
                            taxRateId = this.taxRateId;
                            taxMethod = this.taxMethod;
                        }


                        if (qty != null && qty != undefined && qty != 0) {
                            this.saleProducts.push({
                                rowId: this.createUUID(),
                                productId: prod.id,
                                itemId: soItem.id,
                                unitPrice: so ? price : '0',
                                quantity: qty,
                                highQty: soItem.highQty,
                                soQty: qty,
                                discount: soItem.discount,
                                fixDiscount: soItem.fixDiscount,
                                lineItemVAt: 0,
                                description: soItem.description,
                                serviceItem: prod.serviceItem,
                                promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                                quantityOut: soItem.quantityOut == null ? null : soItem.quantityOut,
                                bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                                taxRateId: soItem.taxRateId,
                                saleReturnDays: prod.saleReturnDays,
                                taxMethod: soItem.taxMethod,
                                rate: rate,
                                serial: soItem.serial,
                                guaranteeDate: soItem.guaranteeDate,
                                isSerial: newProduct.serial,
                                guarantee: newProduct.guarantee,
                                currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                                lineTotal: prod.salePrice * 1,
                                buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                                get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                                quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                                offerQuantity: 0,
                                unitPerPack: newProduct.unitPerPack,
                                levelOneUnit: prod.levelOneUnit,
                                basicUnit: prod.basicUnit,
                                isFree: soItem.isFree,
                                styleNumber: soItem.styleNumber,

                                discountSign: '%',
                            });
                        }
                        else {
                            this.saleProducts.push({
                                rowId: this.createUUID(),
                                productId: prod.id,
                                unitPrice: prod.salePrice == 0 ? '0' : prod.salePrice,
                                quantity: this.isMultiUnit == 'true' ? 0 : 1,
                                soQty: 0,
                                highQty: 0,
                                discount: 0,
                                fixDiscount: 0,
                                lineItemVAt: 0,
                                description: newProduct.description,
                                styleNumber: newProduct.styleNumber,
                                serviceItem: prod.serviceItem,
                                currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                                promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                                bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                                taxRateId: taxRateId,
                                saleReturnDays: prod.saleReturnDays,
                                taxMethod: taxMethod,
                                rate: rate,
                                serial: '',
                                guaranteeDate: '',
                                isSerial: newProduct.serial,
                                guarantee: newProduct.guarantee,
                                lineTotal: prod.salePrice * 1,
                                buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                                get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                                quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                                offerQuantity: 0,
                                unitPerPack: newProduct.unitPerPack,
                                levelOneUnit: prod.levelOneUnit,
                                basicUnit: prod.basicUnit,
                                isFree: false,
                                discountSign: '%',
                            });
                        }

                    }
                    var product = this.saleProducts.find((x) => {
                        return x.productId == productId;
                    });

                    this.getVatValue(product.taxRateId, product);
                    this.updateLineTotal(product.quantity, "quantity", product);
                    this.updateLineTotal(product.highQty, "highQty", product);

                    this.product.id = "";
                    this.rendered++;
                }
            },
            EmtySaleProductList: function () {

                this.saleProducts = [];
            },
            createUUID: function () {

                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getVatValue: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
                return vat.rate;
            },
            getTaxMethod: function (method, prod) {
                prod.taxMethod = method;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
            },
            removeProduct: function (id) {

                this.saleProducts = this.saleProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getData: function () {
                var root = this;

                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {


                        if (root.saleItems != undefined) {

                            root.saleItems.forEach(function (item) {
                                var vat = root.vats.find((value) => value.id == item.taxRateId);
                                if (root.formName === 'saleReturn' && item.remainingQuantity <= 0)
                                    return
                                if (item.productId != null) {
                                    root.products.push(item.product);

                                    root.saleProducts.push({
                                        rowId: item.id,
                                        productId: item.productId,
                                        itemId: item.soItemId,
                                        unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        quantity: item.quantity,
                                        highQty: item.highQty,
                                        discount: item.discount,
                                        offerQuantity: item.offerQuantity == undefined ? 0 : item.offerQuantity,
                                        fixDiscount: item.fixDiscount,
                                        taxRateId: item.taxRateId,
                                        taxMethod: item.taxMethod,
                                        rate: vat.rate,
                                        soQty: item.soQty,
                                        currentQuantity: item.product.inventory == null ? 0 : item.product.inventory.currentQuantity,
                                        saleReturnDays: item.saleReturnDays,
                                        lineTotal: item.unitPrice * item.quantity,
                                        unitPerPack: item.unitPerPack,
                                        levelOneUnit: item.product.levelOneUnit,
                                        basicUnit: item.product.basicUnit,
                                        description: item.description,
                                        styleNumber: item.styleNumber,
                                        serviceItem: item.serviceItem,
                                        isFree: item.isFree,
                                        serial: item.serial,
                                        guaranteeDate: item.guaranteeDate,
                                        isSerial: item.product.serial,
                                        guarantee: item.product.guarantee,
                                        discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                    });
                                }
                                else {

                                    root.saleProducts.push({
                                        rowId: item.id,
                                        productId: item.productId,
                                        itemId: item.soItemId,
                                        unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        quantity: item.quantity,
                                        highQty: 0,
                                        discount: item.discount,
                                        offerQuantity: item.offerQuantity == undefined ? 0 : item.offerQuantity,
                                        fixDiscount: item.fixDiscount,
                                        taxRateId: item.taxRateId,
                                        taxMethod: item.taxMethod,
                                        rate: vat.rate,
                                        soQty: item.soQty,
                                        currentQuantity: 0,
                                        saleReturnDays: item.saleReturnDays,
                                        lineTotal: item.unitPrice * item.quantity,
                                        unitPerPack: item.unitPerPack,
                                        levelOneUnit: '',
                                        basicUnit: '',
                                        description: item.description,
                                        styleNumber: item.styleNumber,
                                        serviceItem: item.serviceItem,
                                        isFree: item.isFree,
                                        serial: item.serial,
                                        guaranteeDate: item.guaranteeDate,
                                        isSerial: false,
                                        guarantee: false,

                                        discountSign: item.discount == 0 ? item.fixDiscount == 0 ? '%' : 'F' : '%',
                                    });
                                }



                                var product = root.saleProducts.find((x) => {
                                    return x.rowId == item.id;
                                });

                                root.getVatValue(product.taxRateId, product);
                                root.updateLineTotal(product.quantity, "quantity", product);
                                root.updateLineTotal(product.highQty, "highQty", product);
                                root.product.id = "";
                                root.rendered++;
                            });
                            root.oveallDiscount = (root.discountProp == null || root.discountProp == undefined || root.discountProp == '') ? 0 : root.discountProp
                            root.calcuateSummary();
                            root.$emit("details", root.saleProducts);
                        }
                    });
            },
        },
        created: function () {
            if (this.$i18n.locale == 'en') {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            this.invoiceWoInventory = localStorage.getItem('InvoiceWoInventory') == 'true' ? true : false;
            this.saleDefaultVat = localStorage.getItem('SaleDefaultVat');

            //this.$barcodeScanner.init(this.onBarcodeScanned);
            //For Scanner Code
            var barcode = '';
            var interval;
            document.addEventListener('keydown', function (evt) {
                if (interval)
                    clearInterval(interval);
                if (evt.code === 'Enter') {
                    if (barcode) {
                        root.onBarcodeScanned(barcode);
                    }
                    barcode = '';
                    return;

                }
                if (evt.key !== 'Shift')
                    barcode += evt.key;
            });

            //End
            this.getData();
            var root = this;
            localStorage.setItem("BarcodeScan", 'SaleItem')
            if (this.$route.query.mobiledata != undefined) {
                //root.purchaseProducts = root.$route.query.data.mobileOrderItemLookupModels;
                for (var j = 0; j < this.$route.query.mobiledata.mobileOrderItemLookupModels.length; j++) {

                    /*  this.saleProducts.rowId[j] = this.$route.query.mobiledata.mobileOrderItemLookupModels[j].id[j];*/
                    this.saleProducts.quantity[j] = this.$route.query.mobiledata.mobileOrderItemLookupModels[j].quantity[j];
                    //root.updateLineTotal(root.purchaseProducts[j].quantity, "quantity", root.purchaseProducts[j]);
                    //root.updateLineTotal(root.purchaseProducts[j].unitPrice, "unitPrice", root.purchaseProducts[j]);

                }
                root.calcuateSummary();
                this.saleProducts.rowId = this.$route.query.mobiledata.mobileOrderItemLookupModels.rowId;
                this.saleProducts.quantity = this.$route.query.mobiledata.mobileOrderItemLookupModels.quantity;
            }
        },
        mounted: function () {
            this.isDiscountBeforeVat = localStorage.getItem('DiscountBeforeVat') == 'true'?true:false
            this.soInventoryReserve = localStorage.getItem('SoInventoryReserve') == 'true' ? true : false;
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            /*this.isMultiUnit = localStorage.getItem('IsMultiUnit');*/
            this.isMultiUnit = 'false';

            this.currency = localStorage.getItem('currency');
            this.dayStart = localStorage.getItem('DayStart');
            this.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;



            this.changePriceDuringSale = localStorage.getItem('changePriceDuringSale');
            this.changePriceDuringSale == 'true' ? (this.changePriceDuringSale = true) : (this.changePriceDuringSale = false);
            this.giveDiscountDuringSale = localStorage.getItem('giveDicountDuringSale');
            this.giveDiscountDuringSale == 'true' ? (this.giveDiscountDuringSale = true) : (this.giveDiscountDuringSale = false);
            this.GetProductList();
        },

    };
</script>
<style scoped>
    .checkBoxHeight {
        width: 20px;
        height: 30px;
    }

    #sale-item td {
        padding-bottom: 5px;
        padding-top: 5px;
    }


    .input-border {
        border: transparent;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .samary_tbl_pd td {
        padding: 20px 7px !important;
    }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }
</style>
