<template>

    <div class="row">
        <div class="card col-lg-9 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="modal-header">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{$t('DiscountSetup.DiscountSetup')}} </h5>

                        </div>
                        <div>
                            <div class="card-body " :key="rander">
                                <div class="row ">

                                   
                                    <div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.lineItemBeforeVat" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(1)"/>
                                            <label class="text  font-weight-bolder">{{$t('DiscountSetup.DiscountBeforeVat')}} </label>
                                        </span>
                                    </div>
                                    <div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.lineItemAfterVat" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(2)"/>
                                            <label class="text  font-weight-bolder"> {{$t('DiscountSetup.DiscountAfterVat')}} </label>
                                        </span>
                                    </div>
                                    <!--<div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.overAllBeforeVat" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(3)"/>
                                            <label class="text  font-weight-bolder"> {{$t('DiscountSetup.OverAllBeforeVat')}} </label>
                                        </span>
                                    </div>
                                    <div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.overAllAfterVat" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(4)"/>
                                            <label class="text  font-weight-bolder"> {{$t('DiscountSetup.OverAllAfterVat')}} </label>
                                        </span>
                                    </div>-->
                                    <div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.discountOverQty" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(5)"/>
                                            <label class="text  font-weight-bolder">{{$t('DiscountSetup.DiscountOverQty')}} </label>
                                        </span>
                                    </div>
                                    <div class=" col-sm-4 ">
                                        <span>
                                            <toggle-button v-model="discountSetup.discountOver1Qty" class="pr-2 pl-2 pt-2" color="#3178F6" v-on:change="changingBehaviour(6)"/>
                                            <label class="text  font-weight-bolder">  {{$t('DiscountSetup.DiscountOver1Qty')}}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" >
                            <button type="button" class="btn btn-primary  " v-on:click="SaveDiscountSetup"> {{$t('DiscountSetup.Save')}}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{$t('DiscountSetup.Close')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                rander:0,
                discountSetup: {
                    id: '00000000-0000-0000-0000-000000000000',
                    lineItemBeforeVat:false,
                    lineItemAfterVat:false,
                    overAllBeforeVat:false,
                    overAllAfterVat:false,
                    discountOverQty:false,
                    discountOver1Qty:false,
                },
                
            }
        },
        
        methods: {
            changingBehaviour: function (data) {
                if (data == 1 ) {
                    this.discountSetup.lineItemBeforeVat = true
                    this.discountSetup.lineItemAfterVat = false
                }
                if (data == 2 ) {
                    this.discountSetup.lineItemBeforeVat = false
                    this.discountSetup.lineItemAfterVat = true
                }
                if (data == 3 ) {
                    this.discountSetup.overAllBeforeVat = true
                    this.discountSetup.overAllAfterVat = false
                }
                if (data == 4 ) {
                    this.discountSetup.overAllBeforeVat = false
                    this.discountSetup.overAllAfterVat = true
                }
                if (data == 5 ) {
                    this.discountSetup.discountOverQty = true
                    this.discountSetup.discountOver1Qty = false
                }
                if (data == 6 ) {
                    this.discountSetup.discountOverQty = false
                    this.discountSetup.discountOver1Qty = true
                }
                this.rander++
            },
            SaveDiscountSetup: function () {
                var root = this;
                var token = localStorage.getItem('token');
                this.$https.post('/Sale/SaveDiscountSetup', this.discountSetup, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {
                        localStorage.setItem('DiscountBeforeVat', root.discountSetup.lineItemBeforeVat);
                        localStorage.setItem('DiscountOverQty', root.discountSetup.discountOverQty);
                        root.$swal({
                            title: 'Updated',
                            text: 'Updated Successfully',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });



                    }
                    else {
                        root.$swal({
                            title: 'Error',
                            text: 'SomethingWrong',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            },
            GetDiscountRecord: function () {
                var root = this;
                var token = localStorage.getItem('token');
                this.$https.get('/Sale/GetDiscountSetup', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    if (response.data != null) {

                        root.discountSetup.lineItemBeforeVat = response.data.lineItemBeforeVat;
                        root.discountSetup.lineItemAfterVat = response.data.lineItemAfterVat;
                        root.discountSetup.overAllBeforeVat = response.data.overAllBeforeVat;
                        root.discountSetup.overAllAfterVat = response.data.overAllAfterVat;
                        root.discountSetup.discountOverQty = response.data.discountOverQty;
                        root.discountSetup.discountOver1Qty = response.data.discountOver1Qty;
                        root.rander++;
                    }
                });

            },
           

            close: function () {
                this.$router.push('/StartScreen');
            },
        },
        created() {
            this.$emit('input', this.$route.name);

            
            

        },
        mounted: function () {

            this.GetDiscountRecord();


        }
    }
</script>